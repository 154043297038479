import React, { useState, useEffect, createContext } from "react";
import { refreshToken } from "./utils/api";
import { BrowserRouter, Navigate } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Register from "./pages/Register";
import RequestPasswordReset from "./pages/RequestPasswordReset";
import PasswordReset from "./pages/PasswordReset";
import RequestPasswordResetSuccess from "./pages/RequestPasswordResetSuccess";
import LoginSSO from "./pages/LoginSSO";
import { createTheme, ThemeProvider } from "@mui/material";

interface AuthContextType {
  loggedIn: boolean;
  setLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
}

const AuthContext = createContext<AuthContextType>({
  loggedIn: false,
  setLoggedIn: () => {},
});

interface UserInfoMessage {
  email: string;
  message: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
}

const UserAlertContext = createContext<UserInfoMessage>({
  email: "",
  message: "",
  setEmail: () => {},
  setMessage: () => {},
});

const theme = createTheme({
  palette: {
    secondary: {
      main: '#6334CC'
    }
  }
});

const App: React.FC = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [userEmail, setUserEmail] = useState("");
  const [infoMessage, setInfoMessage] = useState("");

  useEffect(() => {
    if (!initialLoading) {
      return;
    }

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let redirectUrl = urlParams.get("redirectUrl");

    refreshToken()
      .then(() => {
        if (redirectUrl) {
          window.location.replace(redirectUrl);
        } else {
          setLoggedIn(true);
        }
      })
      .catch(() => {})
      .finally(() => {
        setInitialLoading(false);
      });
  }, [initialLoading]);

  if (initialLoading) {
    return <></>;
  }

  if (loggedIn) {
    return (
      <ThemeProvider theme={theme}>
        <AuthContext.Provider value={{ loggedIn, setLoggedIn }}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="*" element={<Navigate to={"/"} />} />
            </Routes>
          </BrowserRouter>
        </AuthContext.Provider>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <AuthContext.Provider value={{ loggedIn, setLoggedIn }}>
        <UserAlertContext.Provider
          value={{
            email: userEmail,
            message: infoMessage,
            setEmail: setUserEmail,
            setMessage: setInfoMessage,
          }}
        >
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/sso" element={<LoginSSO />} />
              <Route path="/register" element={<Register />} />
              <Route
                path="/reset-password"
                element={<RequestPasswordReset />}
              />
              <Route
                path="/password-reset-success"
                element={<RequestPasswordResetSuccess />}
              />
              <Route
                path="/reset-password/:token"
                element={<PasswordReset />}
              />
              <Route path="*" element={<Navigate to={"/"} />} />
            </Routes>
          </BrowserRouter>
        </UserAlertContext.Provider>
      </AuthContext.Provider>
    </ThemeProvider>
  );
};

export default App;

export { AuthContext, UserAlertContext };
