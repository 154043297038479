import { ChangeEvent, useContext, useEffect, useState } from "react";
import { oidcLogin, oidcLoginCallback } from "./../utils/api";
import { Alert, LinearProgress } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import logo from "./../logo_allobrain.svg";
import styled from "@emotion/styled";
import { AuthContext } from "../App";
import { UserAlertContext } from "../App";
import { useNavigate, useSearchParams } from "react-router-dom";

const Container = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f5f5f5;
`;

const FormContainer = styled(Box)`
  width: 400px;
  padding: 16px;
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
  background-color: white;
  display: flex;
  flex-direction: column;
`;

const Login: React.FC = () => {
  const { email, setEmail } = useContext(UserAlertContext);
  const { loggedIn, setLoggedIn } = useContext(AuthContext);
  const [exchangingCode, setExchangingCode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | string>();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const redirectUrlKey = `redirectUrl`;
    const redirectUrl = searchParams.get('redirectUrl');
    const state = searchParams.get('state');
    const code = searchParams.get('code');

    if (state && code) {
      setExchangingCode(true);
      oidcLoginCallback(code, state)
        .then(() => {
          setLoggedIn(true);
          const redirectUrl = localStorage.getItem(redirectUrlKey);
          if (redirectUrl) {
            window.location.href = redirectUrl;
          }
        })
        .catch((err) => {
          if (err?.response?.status === 401) {
            setError("Unauthorized");
          } else {
            setError("Unable to log in.");
          }
        })
        .finally(() => setExchangingCode(false));
    } else {
      if (redirectUrl) {
        localStorage.setItem(redirectUrlKey, redirectUrl);
      } else {
        localStorage.removeItem(redirectUrlKey);
      }
    }
  }, [searchParams, setLoggedIn, navigate]);

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleSSO = async () => {
    setLoading(true);
    setError(null);
    try {
      const response: any = await oidcLogin(email);
      window.location.href = response.data.authorizeUrl;
    } catch {
      setError("Unable to log in.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <FormContainer>
        <Box
          sx={{ backgroundColor: "#000", padding: "20px", textAlign: "center" }}
        >
          <img src={logo} alt="AlloBrain Logo" />
        </Box>
        {exchangingCode && <Alert sx={{ marginTop: "20px", justifyContent: "center" }} severity="info">
          Login in progress
        </Alert>}
        {loggedIn && (
          <Alert sx={{ marginTop: "20px" }} severity="success">
            Logged In
          </Alert>
        )}
        {!exchangingCode && (
          <form>
            <TextField
              label="Email"
              variant="outlined"
              value={email}
              onChange={handleEmailChange}
              margin="normal"
              fullWidth
              name="email"
              disabled={loading}
            />

            {!loading && error && (
              <Alert sx={{ marginTop: "10px" }} severity="error">
                {error}
              </Alert>
            )}

            <Button
              variant="contained"
              color="secondary"
              onClick={handleSSO}
              fullWidth
              sx={{ marginTop: "20px" }}
              disabled={loading || loggedIn}
            >
              Log In With SSO
            </Button>

            {!loading && (
              <Box
                sx={{
                  mt: 2,
                  textDecoration: "underline",
                  color: "black",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate({
                    pathname: "/",
                    search: window.location.search,
                  });
                }}
              >
                Go to Login page
              </Box>
            )}
          </form>
        )}
        {(loading || exchangingCode) && <LinearProgress sx={{ height: "2px" }} />}
      </FormContainer>
    </Container>
  );
};

export default Login;
